import { Link } from 'react-router-dom'
import './Temoignages.scss'
import React, { useState } from 'react'

function Temoignages() {
  const [showAll, setShowAll] = useState(false);

  const handleRedirect = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const data = [
    {
      img: 'image3.png',
      prenomVille: 'Kathleen',
      date: 'Juin 2023',
      temoignage:
        "Cette exploration de mon fonctionnement m'a apporté une meilleure connaissance de soi dans un moment de prendre du temps pour soi. Une explication sur ma bizarrerie de l'enfance. J'ai moins de jugement sur moi aujourd'hui mais je me dis que enfant savoir cela m'aurait permis de mieux me comprendre et de me pardonner mon comportement au lieu de le juger si mal. Je me serais sentie comprise. Du coup, j'ai beaucoup d'indulgence pour l'enfant que j'étais et je regarde d'un œil différent celle d'aujourd'hui. \n J'ai apprécié ta bonté, ta gentillesse. Tu es adorable et tu as l'écoute alors on te fait confiance facilement. \n\n J'ai vraiment trouvé rigolo les questions. \n\n Et de prendre du temps pour moi ce que j'ai du mal a faire. D'ailleurs depuis toi et le test en juin. Je me suis inscrite à des cours à l'université pour moi, pour mon plaisir… je suis séparée de mon conjoint ( j'ai retrouvé en sérénité)… j'envisage même de reprendre la course sans avoir saisi encore l'opportunité de me lancer. \n\n Je pense que certaines choses qu'on s'est dites on fait des liens ou des déclics intéressants.",
    },
    {
      img: 'image2.png',
      prenomVille: 'Jérôme',
      date: 'Septembre 2023',
      temoignage:
        "Cette exploration m'a permis de confirmer que mon profil avait bien des spécificités neuro atypiques. Ce qui me permet de mieux comprendre mon fonctionnement et du coup lâcher prise sur certaines choses sur lesquelles parfois je pouvais un peu m'acharner \n\n J'ai apprécié l'engagement dont tu as fait preuve et la restitution correcte de mon interview. Le partage des situations avec des réactions un peu similaires (on se sent toujours moins seul avec des personnes qui fonctionnent \"un peu comme nous`\" d'ailleurs je me demande quel est le pourcentage de multi potentiel dans la population. ?",
    },
    {
      img: 'image4.png',
      prenomVille: 'Sophie',
      date: 'Septembre 2023',
      temoignage:
        "Cette exploration m'a apporté : \n- des confirmations, validations de traits de caractère que je savais ou qu'on m'avait déjà évoqués \n- des nouveaux concepts : des mots que je ne connaissais pas \n- des échanges enrichissants \n- des liens entre la CNV et d'autres approches  \n- de la confiance : je ne suis pas une extraterrestre  \n- un temps pour moi \n\n J'ai apprécié \n - La mise en lien entre les réponses et les différents facettes de la haute sensibilité. \n - Les partages d'expérience \n - Les explications des différents concepts  \n - le fait de ne pas étiqueter mais de donner de la compréhension sur un mécanisme de réflexion, sur un fonctionnement différent ",
    },
    {
      img: 'image1.png',
      prenomVille: 'Clémentine',
      date: 'Avril 2024',
      temoignage:
        "Sache que ton accompagnement m'apporte également beaucoup. \n J'adore ce combo coaching / accompagnement hypersensibilité avec l'hypnose, \n qui me permet de conscientiser mes propres ressources, de les mobiliser, de me poser les bonnes questions et retrouver beaucoup de clarté et de confiance. \n Ces séances m'aident à libérer mes émotions et me boostent en énergie ! \n\n\n Je te remercie pour ton regard si bienveillant, ton écoute active et sans jugement et tous tes éclairages si judicieux. \n Je me suis tout de suite sentie en confiance et tu as su trouver le format idéal, qui me permet à la fois de me déposer et me libérer tout en me poussant vers l'action ! \n Depuis qu'on a recommencé nos séances, le fait de poser mes objectifs avec toi me donne envie d'avancer, de me surpasser. \n C'est comme si j'y croyais davantage et je me sens de plus en plus capable d'y arriver.\n Comme si je prenais davantage conscience de mes forces et de ma valeur. \n Cela m'apporte également beaucoup de douceur envers moi-même, \n et me permet de savourer chaque petite goutte d'eau de mon océan avec davantage de fierté et de reconnaissance. \n\n\n Nulle doute que tu es faite pour l'accompagnement ! \n Je te souhaite le meilleur dans ce projet de cœur, qui te permettra de mettre en lumière tous les trésors que tu as en toi. ✨\n Ne doute pas de ta réussite, \n car ce projet n'est autre que la continuité de toi-même et de tes talents naturels.",
    },
    {
      img: 'image6.png',
      prenomVille: 'Anna 37 ans, Orthophoniste',
      date: 'Juillet 2024',
      temoignage:
        "J'ai fait le test de douance avec Delphine. J'ai aimé l'exercice d'introspection que cela demande et ça m'a déjà appris sur moi d'entendre mes réponses. J'ai aimé le temps d'écoute que Delphine m'a accordé, sa patience, sa bienveillance. Elle a pris le temps pour m'expliquer en détail les résultats. J'étais quelque peu sceptique mais après son retour j'ai pu analyser mon comportement au vu des connaissances que j'avais accumulées grâce à elle et j'ai pu prendre conscience de mon fonctionnement à travers mon comportement. Elle m'a permis de diriger mon chemin d'évolution personnelle vers l'acceptation de qui je suis, de comment je fonctionne sans me juger. Elle m'a permis de donner du sens à ce que je vivais là où parfois je ne me comprends pas. Ça m'a ouvert des voies que je ne soupçonnais pas. \n\n Je recommande à tous de faire ce test et d'être suivi par Delphine pour se sentir légitime d'être pleinement soi. C'est un chemin semé d'embuches mais Delphine est là pour nous tenir la main, nous éclairer, nous pousser à voir notre lumière, ce qui nous rend unique, ce que l'on apporte au monde. Et ceux malgré nos pensées limitantes et nos jugements d'intérieurs. Ça permet de travailler sur un meilleur ancrage en soi, plus de bienveillance pour soi. De le faire ressentir aux autres et de contribuer peut-être à leur bien être en étant soi-même plus serein.",
    },
    {
      img: 'image7.png',
      prenomVille: 'Cécile Avocate d\'affaires',
      date: 'Juillet 2024',
      temoignage:
        "Merci beaucoup Delphine pour les prises de conscience que tu m’as permis de faire au cours de cette séance ✨ j’en ressors en mesurant encore davantage l’importance de prendre du recul sur mes besoins avant de m’engager trop rapidement 🙏 \n\n Tu m’as accordé une écoute bienveillante & rassurante et a su me poser les bonnes questions pour me faire cheminer vers une meilleure connaissance de moi💗 \n\n Je pense sincèrement que cela va m’apporter de belles choses. \n\n Merci encore. Cécile",
    },
    {
      img: 'image8.png',
      prenomVille: '',
      date: 'Octobre 2024',
      temoignage:
        "Je m'appelle Jérôme, j'ai 50 ans et je suis coordinateur logistique en pleine transition professionnelle. J'ai fait appel à Delphine pour un accompagnement en coaching, et dès le début, j'ai été impressionné par la qualité de ses services. Elle s'est montrée extrêmement à l'écoute, bienveillante et humble, ce qui a rendu nos échanges très fluides. \n\n Avant le coaching, je manquais d'organisation et j'avais du mal à comprendre certains aspects liés à mon hypersensibilité, ce qui affectait ma prise de décisions, tant sur le plan personnel que professionnel. Cette situation me laissait avec un sentiment de lassitude et une impression de stagnation. \n\n Grâce au coaching, j'ai pu clarifier ma situation, mieux comprendre mes fonctionnements internes et retrouver de la clarté dans mes objectifs. Je me sens maintenant plus apaisé, plus serein et confiant dans ma capacité à avancer. J'ai décidé de continuer à agir pas à pas pour surmonter cette période de transition. \n\n Ce que j'ai particulièrement apprécié chez Delphine, c'est son approche humaine, sa capacité à écouter et sa bienveillance constante. Je la recommande sans hésiter à toute personne cherchant à retrouver de la clarté et à avancer dans sa vie.",
    },
    {
      img: 'anais-archi-39.png',
      prenomVille: 'Anaïs, 39 ans, architecte d\'intérieur',
      date: 'Décembre 2024',
      temoignage:
        "Ton coaching a été un vrai déblocage pour moi. Tu m'as permis de me poser un temps pour poser les choses, les peser, et les oser 🙂 \n\n J'étais pleine de doutes et je ne parvenais pas à prendre une décision, par peur de son impact pour mon avenir. Ça m'épuisait et ça me stressait, ça ne me quittait pas...  \n\n J'y réfléchissais tout le temps, même lors de mes moments en famille et je cherchais toutes les façons possibles, tous les signes qui pourraient trancher pour moi. Avec ce temps ensemble, tes questions, ton écoute, ta bienveillance tout en me \"confrontant\" à mes réponses, en me poussant dans mes réflexions et mes ressentis, tu m'as aidée à souffler, à prendre du recul et à calmer mon anxiété. \n\n C'est un exercice que je referai sans hésiter, parce qu'oser prendre la décision m'a allégée, apaisée et alignée, libérée d'un poids et de pensées en boucles... Je dors mieux depuis 😁 et à refaire, je le referais sans problème tellement c'est libérateur. \n D'autant qu'une fois la décision prise... il ne s'est rien passé de négatif 🙂 Je ne manquerai pas de parler de toi aux personnes qui vivront ça ! \n\n Merci encore, vraiment, de tout cœur 🙂",
    }
  ]

  const renderTemoignage = (tem, index) => (
    <div className="temoignage glass" key={index}>
      {tem.img && (
        <img
          className="illustration_2"
          src={require(`../../assets/images/temoignage/${tem.img}`).default}
          alt={`temoignage de ${tem.prenomVille} en ${tem.date}`}
          width={150}
          height={150}
        />
      )}

      <div className="content">
        <p dangerouslySetInnerHTML={{ __html: tem.temoignage.replace(/\n/g, '<br />') }} />
        <div>
          {tem.prenomVille && <div>{tem.prenomVille}</div>}
          {tem.date && <div>{tem.date}</div>}
        </div>
      </div>
    </div>
  )

  const displayedData = showAll ? [...data].reverse() : [...data].reverse().slice(0, 3);

  return (
    <section className="sub_container temoignage spaced">
      <div className="text-align-center">
        <h2>Témoignage de personnes que j'ai accompagné</h2>
        <p className="sub_text">
          (Avis Google, extraits d'e-mails, de SMS et de messages sur mes
          réseaux sociaux de personnes que j'ai accompagnées)
        </p>
      </div>
      <div className="temoignage_container">
        {displayedData.map(renderTemoignage)}
      </div>
      {!showAll && (
        <a
          className="btn_secondary"
          onClick={() => setShowAll(true)}
        >
          Voir plus de témoignages
        </a>
      )}
      <Link
        to="/explore"
        className="btn_rervervation"
        onClick={handleRedirect}
      >
        VOIR LES OFFRES
      </Link>
    </section>
  )
}

export default Temoignages